/* eslint-disable camelcase */

import React from 'react'
import { Alert, Button, Form, Modal } from 'react-bootstrap'
import { CreateSelect } from '../../../components/Form'
import Spinner from '../../../components/spinner'
import { NOOP } from '../../../components/utils'
import { useUpdateMemberDetail } from '../../../utils/api'
import utils from '../../../utils/utils'
/**
 *
 * !!! Page !!!
 *
 * @param {Object} props
 * @param {boolean} props.openModal
 * @param {Function} props.setOpenModal
 * @param {Function} props.setOpenDeleteModel
 * @returns
 */
const MyAccountEditMemberModal = (props) => {
  // props
  const { openModal = true, setOpenModal = NOOP, data, setShowDeleteUserModal } = props

  const editing = true
  // states
  const [profileForm, setProfileForm] = React.useState({
    firstName: '',
    lastName: '',
    jobTitle: '',
    phoneNumber: '',
    language: '',
    role: '',
    status: '',
    roleOptions: []
  })
  // functions
  const updateMember = useUpdateMemberDetail()
  React.useEffect(() => {
    if (editing) {
      setProfileForm({
        status: data.status || '',
        firstName: data.firstName || '',
        lastName: data.lastName || '',
        jobTitle: data.jobTitle || '',
        phoneNumber: data.phoneNumber || '',
        language: data.language || '',
        role: data.role || ''
      })
    }
  }, [editing, data])
  // react-query api
  const handleChange = (input) => (e) => {
    setProfileForm((ps) => ({ ...ps, [input]: e.target.value }))
  }
  const saveUserProfile = (e) => {
    e.preventDefault()
    const original = {
      status: data.status || '',
      firstName: data.firstName || '',
      lastName: data.lastName || '',
      jobTitle: data.jobTitle || '',
      phoneNumber: data.phoneNumber || '',
      language: data.language || '',
      role: data.role || ''
    }
    const deltaData = utils.getDifferentBetweenTwoObjects(
      profileForm,
      original
    )
    if (Object.keys(deltaData).length > 0) {
      updateMember
        .mutateAsync({ memberDetails: deltaData, memberId: data.id })
    }
  }

  const deleteUser = () => {
    setShowDeleteUserModal(true)
    setOpenModal(false)
  }

  const onHide = () => {
    setOpenModal(false)
    updateMember.reset()
  }

  return (
    <>
      <Modal show={openModal} onHide={onHide} >
        <Modal.Body>
          <button type="button" className="close" onClick={onHide}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
          <h5 className="m-0 mb-3">Edit user</h5>

          <form>
            <CreateSelect
              label="Status"
              required
              // onChange={handleChange('country')}
              value={editing ? profileForm.status : data.status}
              onChange={handleChange('status')}
              // placeholder={list.role}
              placeholder="Select a status"
              className="custom-select-grey"
              disabled={!editing}
              options={[
                { value: 'active', label: 'Active' },
                { value: 'suspended', label: 'Suspended' }
                // { value: 'declined', label: 'Declined' }
              ]}
            />
            <CreateSelect
              label="Role"
              required
              // onChange={handleChange('country')}
              placeholder="Select a role"
              className="custom-select-grey"
              value={editing ? profileForm.role : data.role}
              onChange={handleChange('role')}
              // placeholder={list.role}
              disabled={!editing}
              options={data.roleOptions}
            />

            <Form.Group>
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                value={editing ? profileForm.firstName : data.firstName}
                onChange={handleChange('firstName')}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Last name</Form.Label>
              <Form.Control
                type="text"
                value={editing ? profileForm.lastName : data.lastName}
                onChange={handleChange('lastName')}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Job title</Form.Label>
              <Form.Control
                type="text"
                value={editing ? profileForm.jobTitle : data.jobTitle}
                onChange={handleChange('jobTitle')}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Phone number</Form.Label>
              <Form.Control
                type="text"
                value={editing ? profileForm.phoneNumber : data.phoneNumber}
                onChange={handleChange('phoneNumber')}
              />
            </Form.Group>
            <CreateSelect
              label="Language"
              required
              // onChange={handleChange('country')}
              value={editing ? profileForm.language : data.language}
              onChange={handleChange('language')}
              placeholder="Select a language"
              className="custom-select-grey"
              disabled={!editing}
              options={[
                { value: 'English', label: 'English' },
                { value: 'Chinese', label: 'Chinese' }
              ]}
            />
          </form>
          {/* buttons */}
          <div className="d-flex justify-content-between mt-5">
            <Button
              type="button"
              onClick={onHide}
              variant="outline-primary"
              style={{ border: 0 + 'px' }}
            >
              {updateMember.isSuccess ? 'Close' : 'Cancel'}
            </Button>
            <div>
            <Button type="button" variant="outline-primary mr-2" onClick={deleteUser} style={{ border: 0 + 'px' }}>
            <span>Delete User</span>
            </Button>
                <Button type="button" variant="primary" onClick={saveUserProfile} disabled={updateMember.isSuccess}>
                    {updateMember.isLoading ? <Spinner /> : <span>Save</span>}
                </Button>
            </div>
          </div>
          {updateMember.isSuccess && (
            <Alert variant="success" className="mb-0 mt-3 text-center">
              Update successful!
            </Alert>
          )}
          {updateMember.isError && (
            <Alert variant="danger" className="mb-0 mt-3 text-center">
              {'Error happens when update: ' + updateMember.error.message}
            </Alert>
          )}
          {/* { process.env.NODE_ENV !== 'production' && <Button className="mt-3" onClick={() => clearFile()}>Clear File</Button>} */}
        </Modal.Body>
        {/* {process.env.NODE_ENV !== 'production' && (<pre>companiesQuery={JSON.stringify(userToInvite, null, 2)}</pre>)} */}
      </Modal>

      {/* {process.env.NODE_ENV !== 'production' && <pre>listingLotSkus={JSON.stringify(listingLotSkus, null, 2)}</pre>} */}
    </>
  )
}

export default MyAccountEditMemberModal
